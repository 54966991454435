import React from 'react';
import { 
    Flex,
    Text,
    Image,
    Link,
    useMediaQuery, 
    useTheme
} from "@chakra-ui/react";

import "../styles/fondo.css";

const Fondo = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
    
    const images = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/fondo1.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/icon-maps.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/fondo1-txt.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/fondo1-txt-mobile.svg' }
    ];

    return (
        <Flex marginBottom={"4rem"} flexDirection={"column"} textAlign={"center"}>
            {isGreaterThanMd ?
                <Text textTransform={"uppercase"} color={"#80A32A"} fontWeight={500} fontSize="49px">
                    El nuevo referente del arte,<br />la cultura y la gastronomía de Jalisco
                </Text>
                :
                <Text p={"0px 2rem"} textTransform={"uppercase"} color={"#80A32A"} fontWeight={500} fontSize="32px">
                    El nuevo referente del arte, la cultura y la gastronomía de Jalisco
                </Text>
            }
            {isGreaterThanMd ?
                <Text color={"#545454"} fontWeight={300} fontSize="24px" mt={5} mb={10}>
                    Una ubicación increíble en el corazón de Zapopan, con gran conectividad a la ciudad y a solo<br />unos pasos de MiBici, la Línea 3, el Centro Histórico, zonas gastronómicas, bares, museos, galerías,<br />restaurantes, andadores, ¡y mucho más!
                </Text>
                :
                <Text color={"#545454"} fontWeight={300} fontSize="20px" mt={5} mb={10}>
                    Una ubicación increíble en el corazón de Zapopan, con gran conectividad a la ciudad y a solo unos pasos de MiBici, la Línea 3, el Centro Histórico, zonas gastronómicas, bares, museos, galerías,<br />restaurantes, andadores, ¡y mucho más!
                </Text>
            }
            <div style={{ backgroundImage: `url('${images[0].imageUrl}')`, alignItems: "center", backgroundSize: 'cover', backgroundPosition: 'center' }} className='fondo-md'></div>
            <Flex justifyContent={"center"} alignItems={"center"} mt={2}>
                {isGreaterThanMd ?
                    <Image src={images[2].imageUrl} style={{ width: isGreaterThanLg ? "80%" : "100%", height: isGreaterThanLg ? "80%" : "100%" }} width={"100"} height={"100"} alt="text maps" />
                    :
                    <Image src={images[3].imageUrl} style={{ width: "80%", height: "80%" }} width={"100"} height={"100"} alt="text maps" />
                }
            </Flex>
            <Flex flexDirection={isGreaterThanMd ? "row" : "column"} justifyContent={"center"} alignItems={"center"} mt={4}>
                <Image m={isGreaterThanMd ? "0px 1rem" : "1rem"} src={images[1].imageUrl} width={isGreaterThanMd ? "10" : "20"} height={isGreaterThanMd ? "10" : "20"} alt="descripción" />
                <Link borderBottom={"2px solid #0882C6"} color={"#0882C6"} fontSize={"16px"} href='https://goo.gl/maps/g6htC9NNHgurnM9JA' isExternal>
                    {isGreaterThanMd ? 
                    <span>Av. Hidalgo 352, Cabecera Municipal, Zapopan, Jalisco.</span> : <span>Av. Hidalgo 352, Cabecera<br />Municipal, Zapopan, Jalisco.</span>}
                </Link>
            </Flex>
        </Flex>
    );
}
 
export default Fondo;