import React, { useEffect } from 'react';
import { 
    Flex,
    Tab,
    Tabs,
    TabPanels,
    TabPanel,
    TabList,
    Grid,
    GridItem,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    TableContainer, 
    TableCaption,
    Text,
    useMediaQuery, 
    useTheme,
} from '@chakra-ui/react';
import { list } from '../../resource';
import './animation.css';
import TableMobile from './TableMobile';

const Cotizador = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

    useEffect(() => {
        const observerL = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show-cotizador');
          } else {
            entry.target.classList.remove('show-cotizador');
          }
        })
        })
          
        const hiddenElementsL = document.querySelectorAll('.hidden-left-cotizador');
        hiddenElementsL.forEach((el) => observerL.observe(el));
          
        const observerR = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('show-cotizador');
            } else {
              entry.target.classList.remove('show-cotizador');
            }
          })
        })
          
        const hiddenElementsR = document.querySelectorAll('.hidden-right-cotizador');
        hiddenElementsR.forEach((el) => observerR.observe(el));
    }, []);

    const images = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/animacion5.svg' }
    ];

    return ( 
        <div className='sections-cotizador'>
            <div className='hidden-left-cotizador'>
                <div className='wrapper-img-cotizador'>
                <img id='animacion5' src={images[0].imageUrl} width="150" height="250" alt='icon scroll'/>
                </div>
            </div>
            <div id='planes-pago' className='hidden-right-cotizador'>
                <Flex
                    color={"#545454"}
                    width={"100%"}
                >
                    <Grid
                        templateColumns={"repeat(1, 1fr)"}
                        w={"100%"}
                        pl={isGreaterThanMd ? "16%" : "0px"}
                        pb={8}
                        pt={isGreaterThanMd ? 10 : 0 }
                        >
                        <GridItem w={isGreaterThanMd ? "70%" : "100%"}>
                            {isGreaterThanMd ?
                                <Tabs variant='enclosed'>
                                    <TabList width={"max-content"}>
                                        <Tab _selected={{ bg: "#E69730", color: "#fff", fontWeight: "500" }}>Contado</Tab>
                                        <Tab _selected={{ bg: "#E69730", color: "#fff", fontWeight: "500" }}>Contado comercial</Tab>
                                        <Tab _selected={{ bg: "#E69730", color: "#fff", fontWeight: "500" }}>Financiamiento</Tab>
                                        <Tab _selected={{ bg: "#E69730", color: "#fff", fontWeight: "500" }}>Hipotecario A</Tab>
                                        <Tab _selected={{ bg: "#E69730", color: "#fff", fontWeight: "500" }}>Hipotecario B</Tab>
                                    </TabList>
                                    <TabPanels>
                                        {list.map((item, idx) => (
                                            <TabPanel key={idx}>
                                                <TableContainer height={"38rem"}>
                                                    <Table size='lg'>
                                                        <TableCaption fontWeight={400} fontSize={"12px"}>*Politica Comercial sujeta a cambio sin previo aviso y sujeto a disponibilidad.</TableCaption>
                                                        <Thead>
                                                            <Tr>
                                                                <Th fontFamily={"Montserrat, sans-serif"} textTransform={"capitalize"} color={"#E69730"}>Descuento</Th>
                                                                <Th color={"#E69730"}>{item.num}</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr>
                                                                <Th fontWeight={600} fontFamily={"Montserrat, sans-serif"}>{item.pay}</Th>
                                                                <Th></Th>
                                                            </Tr>
                                                            {item.tab.map((element, idx1) => (
                                                                <Tr key={idx1}>
                                                                    <Th fontFamily={"Montserrat, sans-serif"} textTransform={"inherit"} fontWeight={400}>{element.item1}</Th>
                                                                    <Th fontFamily={"Montserrat, sans-serif"} textTransform={"inherit"} fontWeight={400}>{element.item2}</Th>
                                                                </Tr>
                                                            ))}
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </TabPanel>
                                        ))}
                                    </TabPanels>
                                </Tabs> : <TableMobile />
                            }
                            
                            <Flex flexDirection={"column"} mb={"1rem"} mt={10}>
                                <Flex justifyContent={"center"}>
                                    <Text textAlign={"center"} textTransform={"uppercase"} fontWeight={600} fontSize={"49px"} color={"#E69730"}>
                                        Prototipos a tu medida
                                    </Text>
                                </Flex>
                                <Flex justifyContent={"center"}>
                                    <Text
                                    fontWeight={300}
                                    fontSize={"24px"}
                                    textAlign={"center"}
                                    color={"#545454"}
                                    padding={"1rem"}
                                    >
                                    Conoce nuestros departamentos de 2 recámaras que se ajustan a tu estilo de vida.
                                    </Text>
                                </Flex>
                            </Flex>
                        </GridItem>
                    </Grid>
                </Flex>
            </div>
        </div>
    );
}
 
export default Cotizador;