import axios from 'axios';
import fileDownload from 'js-file-download';

export const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
}

export const list = [
  {
      bold: true,
      num: "8%",
      pay: "2 PAGOS",
      tab: [
          { item1: "· Pago de enganche 1 exhibición", item2: "90% Firma del contrato"},
          { item1: "· Último pago contra escritura", item2: "10% Escrituras"},
      ]
  },
  {
      bold: false,
      num: "6%",
      pay: "8 PAGOS",
      tab: [
          { item1: "· Pago de enganche 1 exhibición", item2: "25% Firma del contrato"},
          { item1: "· Segundo pago 1 exhibición", item2: "10% A los 60 días nat."},
          { item1: "· Tercer pago 1 exhibición", item2: "10% A los 90 días nat."},
          { item1: "· Cuarto pago 1 exhibición", item2: "10% A los 120 días nat."},
          { item1: "· Quinto pago 1 exhibición", item2: "10% A los 150 días nat."},
          { item1: "· Sexto pago 1 exhibición", item2: "10% A los 180 días nat."},
          { item1: "· Séptimo pago 1 exhibición", item2: "10% A los 210 días nat."},
          { item1: "· Último pago contra escritura", item2: "15% Escritura"}
      ]
  },
  {
      bold: false,
      num: "4%",
      pay: "9 PAGOS",
      tab: [
          { item1: "· Pago de enganche 1 exhibición", item2: "20% Firma del contrato"},
          { item1: "· Pagos mensuales 7 exhibiciones", item2: "60% 7 mensualidades"},
          { item1: "· Último pago contra escritura", item2: "20% Escritura"}
      ]
  },
  {
      bold: false,
      num: "1.25%",
      pay: "4 PAGOS",
      tab: [
          { item1: "· Pago de enganche 1 exhibición", item2: "10% Firma del contrato"},
          { item1: "· Segundo pago 1 exhibición", item2: "10% A los 90 días nat."},
          { item1: "· Tercer pago 1 exhibición", item2: "10% A los 180 días nat."},
          { item1: "· Último pago contra escritura", item2: "70% Escritura"}
      ]
  },
  {
      bold: false,
      num: "0%",
      pay: "9 PAGOS",
      tab: [
          { item1: "· Pago de enganche 1 exhibición", item2: "5% Firma del contrato"},
          { item1: "· Segundo pago 1 exhibición", item2: "5% A los 30 días nat."},
          { item1: "· Pagos diferidos 15 exhibiciones", item2: "15% 6 mensualidades"},
          { item1: "· Último pago contra escritura", item2: "75% Escritura"}
      ]
  }
];