import React, { useState } from 'react';
import { Flex, Text, Grid, GridItem, useMediaQuery, useTheme, IconButton, Image } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';
import "../styles/construvida.scss";
import "../styles/styled.css";

import ArrowLeftIcon from "../assets/svgs/ArrowLeftSVG";
import ArrowRightIcon from "../assets/svgs/ArrowRightSVG";

const Construvida = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
    const [activeIndex, setActiveIndex] = useState(0);

    const slides = [
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/construvida/tya.svg' },
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/icon-flotante.svg' }
    ];

    const slidePrev = () => {
        activeIndex===0?(
          setActiveIndex(listImages[2].images.length-1)):
        setActiveIndex(activeIndex - 1);
                };
    
    const slideNext = () => {
        activeIndex===listImages[2].images.length-1?(
          setActiveIndex(0))
            : setActiveIndex(activeIndex + 1)
    };

    const RenderGridItemTYA = () => {
      return (
        <GridItem alignSelf="center" mt={10}>
          <Flex width={"100%"} mb={isGreaterThanMd ? "20%" : "10%"}>
            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={isGreaterThanMd ? "initial" : "center"}
              color={"#545454"}
            >
              <img
                src={slides[0].imageUrl}
                width="150"
                height="150"
                alt="descripcion"
              />
              {isGreaterThanMd ?
                <Text
                  fontWeight={"500"}
                  fontSize={{ base: "2xl", md: "28px" }}
                  textAlign={{ base: "center", lg: "left" }}
                  m={"1rem 0"}
                >
                  El presente de Tierra y Armonía es una<br />historia de promesas cumplidas, tanto<br />a sus clientes como a sus inversionistas.
                </Text>
              :
                <Text
                  fontWeight={"500"}
                  fontSize={{ base: "2xl", md: "28px" }}
                  textAlign={{ base: "center", lg: "left" }}
                  m={"1rem 0"}
                >
                  El presente de Tierra y Armonía es una historia de promesas cumplidas, tanto a sus clientes como a sus inversionistas.
                </Text>
              }
              <Text
                fontSize={isGreaterThanMd ? "24px" : "20px"}
                fontWeight={300}
                textAlign={{ base: "center", lg: "left" }}
              >
                Somos una empresa sólida gracias a la calidad de personas que forman parte de nuestra compañía. Juntos hemos aprendido a valorar el trabajo colaborativo, la participación y el saber que impactamos de forma positiva en las ciudades. Con cada nuevo proyecto vamos construyendo nuestra historia, la cual abarca ya más de cuatro décadas de ser una empresa en constante renovación, evolución y crecimiento.
              </Text>
            </Flex>
          </Flex>
        </GridItem>
      )
    }
    
    return (
      <Flex
        marginTop={"4rem"}
        id="tya"
        justifyContent={"center"}
      >
        <Grid
          className="section-grid-construvida"
          templateColumns={isGreaterThanLg ? "1fr 50%" : "100%"}
          px={isGreaterThanLg ? 40 : 8}
          width={"100%"}
          pb={5}
          gap={{ base: "2rem", lg: "4rem" }}
        >
          {isGreaterThanMd ? 
            <RenderGridItemTYA /> : null
          }
          <GridItem
            mb={isGreaterThanLg ? 0 : 8}
            mt={isGreaterThanLg ? 0 : 16}
            alignSelf="center"
            maxWidth={isGreaterThanLg ? "100%" : "90%"}
          >
            <Flex
              className="section-construvida-carousel"
              flexDirection={"row"}
              width={"100%"}
              padding={isGreaterThanLg ? "0px 6rem" : "0px 0rem"}
              alignItems="center"
              placeContent={"center"}
              position={"relative"}
            >
              <Image 
                position={"absolute"} src={slides[1].imageUrl} 
                width="150" height="150" 
                alt='icon float'
                top={"-60px"}
                right={"40px"}
                />
              <IconButton
                icon={<ArrowLeftIcon color="black" />}
                rounded="full"
                border="0"
                shadow="md"
                transitionDuration=".5s"
                _hover={{ shadow: "lg" }}
                //disabled={page <= 1}
                onClick={slidePrev}
                position="relative"
                right={{ base: "-4", md: -5 }}
                bg="white"
                zIndex="2"
              />
              <AliceCarousel
                mouseTracking
                //disableDotsControls
                //animationType="fadeout"
                animationDuration={500}
                disableButtonsControls
                activeIndex={activeIndex}
                items={listImages[2].images}
                responsive={true}
                controlsStrategy="responsive"
                animationType="fadeout"
                autoPlayInterval={5000}
                infinite={true}
                keyboardNavigation={true}
                disableDotsControls
                autoHeight={false}
                maxHeight="200px"
              />
              <IconButton
                icon={<ArrowRightIcon color="black" />}
                rounded="full"
                border="0"
                colorScheme="brand"
                shadow="md"
                transitionDuration=".3s"
                _hover={{ shadow: "lg" }}
                onClick={slideNext}
                position="relative"
                left={{ base: "-4", md: -5 }}
                bg="white"
                zIndex="2"
              />
            </Flex>
          </GridItem>
          {!isGreaterThanMd ? 
            <RenderGridItemTYA /> : null
          }
        </Grid>
      </Flex>
    );
}
 
export default Construvida;
