import React, { useEffect } from 'react';
import { 
  Flex, 
  Text,
  useMediaQuery, 
  useTheme,
  Grid,
  GridItem,
  Image
} from '@chakra-ui/react';
import './animation.css';

const Amenidades = () => {
  //Elementos para responsive
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

  const images = [
    { imageUrl: "https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/animacion1.svg" },
    { imageUrl: "https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/amenities.png" },
    { imageUrl: "https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/description-amenities.svg" },
  ]

  useEffect(() => {
    const observerL = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show-amenidades');
      } else {
        entry.target.classList.remove('show-amenidades');
      }
    })
    })
      
    const hiddenElementsL = document.querySelectorAll('.hidden-left-amenidades');
    hiddenElementsL.forEach((el) => observerL.observe(el));
      
    const observerR = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show-amenidades');
        } else {
          entry.target.classList.remove('show-amenidades');
        }
      })
    })
      
    const hiddenElementsR = document.querySelectorAll('.hidden-right-amenidades');
    hiddenElementsR.forEach((el) => observerR.observe(el));
  }, []);

    return ( 
        <div className='sections-amenidades'>
          <div className='hidden-left-amenidades'>
            <div className='wrapper-img-amenidades'>
              <img id='animacion1' src={images[0].imageUrl} width="150" height="250" alt='icon scroll'/>
            </div>
          </div>
          <div className='hidden-right-amenidades'>
            <Grid
              color={"#545454"}
              templateColumns={
                isGreaterThanMd ? "repeat(5, 1fr)" : "repeat(2, 1fr)"
              }
              p={isGreaterThanMd ? "5rem" : "3rem"}
            >
              <GridItem
                colSpan={isGreaterThanMd ? 3 : 2}
              >
                <Flex
                  flexDirection={"column"}
                  px={isGreaterThanMd ? 15 : 2}
                  pr={20}
                  padding={{ base: "0", lg: "0 2rem" }}
                >
                  <Flex paddingBottom={"1.5rem"} flexDirection={"column"}>
                    <Text
                      fontSize={{ base: "24px", md: "49px", lg: "49px" }}
                      fontWeight={isGreaterThanMd ? 300 : 700}
                      fontFamily={"ResistSansText"}
                      textTransform={"uppercase"}
                      textAlign={isGreaterThanMd ? "initial" : "center"}
                      mb={isGreaterThanMd ? 0 : 10}
                    >
                      Descubre la nueva forma de vivir el centro de Zapopan
                    </Text>
                    {!isGreaterThanMd ?
                      <Flex justifyContent="center">
                        <img
                          src={images[1].imageUrl}
                          width={{ base: "100%", md: "50%", lg: "100%", xl: "500px" }}
                          height="100"
                          alt="amenidades"
                        />
                      </Flex>
                    : null}
                  </Flex>
                  <Flex paddingBottom={"1.5rem"}>
                    <Text fontSize={{ base: "18px", lg: "22px" }} fontWeight={300}>
                      En Centro Zapopan disfrutarás de un lifestyle sin igual, que te permitirá 
                      crear las experiencias de vida más extraordinarias e inolvidables, 
                      en un mismo lugar.<br /><br />
                      Además, Centro Zapopan es el único proyecto en México que cuenta con un 
                      museo en su master plan, para que vivas el arte y la cultura de la 
                      ciudad de una manera única.
                    </Text>
                  </Flex>
                  <Flex
                    paddingBottom={isGreaterThanMd ? "1rem" : "0px"}
                    justifyContent={{ base: "center", md: "flex-start" }}
                  >
                    <Image src={images[2].imageUrl} width={"150"} height={"150"} alt="descripción" />
                  </Flex>
                </Flex>
              </GridItem>
              <GridItem colSpan={2} justifyContent="center">
                <Flex display={isGreaterThanMd ? "flex" : "none"} justifyContent="center">
                  <img
                    src={images[1].imageUrl}
                    width={{ base: "100%", md: "50%", lg: "100%", xl: "500px" }}
                    height="100"
                    alt="amenidades"
                  />
                </Flex>
              </GridItem>
            </Grid>
          </div>
        </div>
    );
}
 
export default Amenidades;