import React, { useEffect, useState } from 'react';
import {
  Flex,
  Link,
  useMediaQuery,
  useTheme,
  useDisclosure,
  Box,
  Center,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
} from '@chakra-ui/react';
import Presentacion from '../../components/Presentacion';
import Caracteristicas from '../../components/Caracteristicas';
import DptoAmenidades from '../../components/DptoAmenidades';
import NuevoZapopan from '../../components/NuevoZapopan';
import Departamentos from '../../components/Departamentos';
import Cotizador from '../../components/Cotizador';
import Prototipos from '../../components/Prototipos';
import Fondo from '../../components/Fondo';
import Sitio from '../../components/Sitio';
import Construvida from '../../components/Construvida';
import Contacto from '../../components/Contacto';
import Footer from '../../components/Footer';
import ButtonModal from '../../components/ButtonModal';
import Amenidades from '../../components/Amenidades';
import ContactoMobile from '../../components/Contacto/Mobile';
import ModalCard from '../../components/ModalCard';
import { FaWhatsapp } from 'react-icons/fa';

const Home = () => {
  //Elementos para responsive
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure();
  const [isFb, setIsFb] = useState(false);

  const icons = [
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/ic-wsp.svg' }
  ];

  useEffect(() => {
    // Obtén la URL actual
    const queryString = window.location.search;
    // Analiza la URL para obtener los parámetros
    const urlParams = new URLSearchParams(queryString);

    // Obten los valores de los parámetros que necesitas
    const param = urlParams.get('from');

    if (param) {
      setIsFb(true);
      setTimeout(() => {
        onOpen();
        onOpenDrawer();
      }, 3000);
    }
  }, []);

  return (
    <>
      {/* ===== BOTTOM MENU FOR MOBIL AND FACEBOOK USERS ===== */}
      {isFb ? (
        <Box
          zIndex={4}
          display={{ base: 'block', md: 'none' }}
          position='fixed'
          bottom='0px'
          left='0px'
          w='100%'
          h='82px'
          bg='#FFFFFF'
          py={4}
        >
          <Center>
            <Button
              w='75%'
              size='lg'
              bg='#179848'
              _hover={{
                bg: '#127337'
              }}
              leftIcon={<FaWhatsapp size='25px' />}
              onClick={() => onOpenDrawer()}
            >
              Whatsapp
            </Button>
          </Center>
        </Box>
      ) : null}
      {/* ===== SHOW MODAL ONLY WHEN IS USER FROM FACEBOOK ===== */}
      <ModalCard
        isOpen={isOpen}
        onClose={onClose}
        title={'Envíanos un Whatsapp'}
        thankYouPage={"thankyoupage-contactar-whatsapp"}
        idForm={"ba872f39-5a74-4ed1-8144-2ff44c14ac53"}
        isOpenDrawer={isOpenDrawer}
        onCloseDrawer={onCloseDrawer}
        facebook={isFb}
      />
      <Flex position={'fixed'} right={{ base: 2, md: '20px' }} bottom={5} zIndex={1}>
        <ButtonModal
          idForm={"ba872f39-5a74-4ed1-8144-2ff44c14ac53"}
          thankYouPage={"thankyoupage-contactar-whatsapp"}
          isImage={true}
          facebook={isFb}
          srcImg={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/redes-sociales/wa.svg'}
          title={'Envíanos un Whatsapp'}
          title_form={'form_whatsapp'}
        />
      </Flex>
      <Presentacion />
      <Amenidades />
      <Caracteristicas />
      <DptoAmenidades />
      <NuevoZapopan />
      <Departamentos />
      <Cotizador />
      <Prototipos />
      <Fondo />
      <Sitio />
      <Construvida />
      {isGreaterThanMd ?
        <Contacto /> : <ContactoMobile />
      }
      <Footer />
      {/*<Modal isOpen={isOpen} onClose={onClose} size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Link target="_blank" href='https://share.hsforms.com/1KIswvZUSQbWR_3zOwMHo2Q3j49k'>
              <Image
                src={'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/banner.webp'}
              />
            </Link>
          </ModalBody>
        </ModalContent>
      </Modal>*/}
    </>
  );
};

export default Home;