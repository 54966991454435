import React, { useState } from 'react';
import { Flex, Text, IconButton, Image, useMediaQuery, useTheme } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';

import '../styles/styled.css';

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const DptoAmenidades = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [activeIndex, setActiveIndex] = useState(0);
    
    const slidePrev = () => {
        activeIndex===0?(
          setActiveIndex(listImages[0].images.length-1)):
        setActiveIndex(activeIndex - 1);
    };
    
    const slideNext = () => {
        activeIndex===listImages[0].images.length-1?(
          setActiveIndex(0))
            : setActiveIndex(activeIndex + 1)
    };

    const images = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/dpt-amenidades.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/dpt-amenidades-mobile.svg' }
    ];

    return (
        <Flex id='amenidades' pt={isGreaterThanMd ? 0 : 20} className='wrapper-all-sections-carrusel-alice' 
            flexDirection={"column"} alignItems={"center"} m={"4rem 0px"} mb={isGreaterThanMd ? "1rem" : "4rem"}>
            <Flex flexDirection={"column"} alignItems={"center"} color={"#545454"} mt={isGreaterThanMd ? 0 : 10}>
                <Image mb={isGreaterThanMd ? 0 : 5} src={images[isGreaterThanMd ? 0 : 1].imageUrl} style={{ width: "900px", height: "100px" }} alt='icon' />
                {isGreaterThanMd ?
                    <Text fontSize={'24px'} textAlign={"center"} fontWeight={300}>Amenidades para que vivas más cómodo y feliz, mientras<br />disfrutas el mejor momento de tu vida.</Text>
                    :
                    <Text fontSize={'lg'} textAlign={"center"} fontWeight={300}>Amenidades para que vivas más cómodo<br />y feliz, mientras disfrutas el mejor momento<br />de tu vida.</Text>
                }
            </Flex>
            <Flex className='section-carrusel-mons' justifyContent={"center"} flexDirection={"row"} width={isGreaterThanMd ? "100%" : "80%"}  alignItems={"center"}>
                <IconButton
                    icon={<ChevronLeftIcon width="64.000000pt" height="54.000000pt"/>}
                    border="0"
                    className='btn-carrusel-mons-izq'
                    background={"transparent"}
                    transitionDuration=".3s"
                    position={isGreaterThanMd ? "relative" : "absolute"}
                    onClick={() => slidePrev()}
                    left={{ base: "26px", md: 40 }}
                    _hover={{ background: "transparent" }}
                    zIndex="2"
                />
                <AliceCarousel
                    mouseTracking
                    animationDuration={800}
                    disableButtonsControls
                    activeIndex={activeIndex}
                    items={listImages[0].images}
                    responsive={true}
                    controlsStrategy="responsive"
                    animationType='fadeout'
                    autoPlay={true}
                    autoPlayInterval={5000}
                    infinite={true}
                    keyboardNavigation={true}
                    disableDotsControls
                    autoHeight={false}
                    maxHeight="200px"
                />
                <IconButton
                    icon={<ChevronRightIcon width="64.000000pt" height="54.000000pt"/>}
                    border="0"
                    className='btn-carrusel-mons-drc'
                    justifyContent={isGreaterThanMd ? "center" : "end"}
                    background={"transparent"}
                    transitionDuration=".3s"
                    onClick={() => slideNext()}
                    position={isGreaterThanMd ? "relative" : "absolute"}
                    left={{ base: "370px", md: -40 }}
                    _hover={{ background: "transparent" }}
                    zIndex="2"
                />
            </Flex>
        </Flex>
    );
}
 
export default DptoAmenidades;