import React, { useEffect } from 'react';
import { 
    Flex,
    Text,
    useMediaQuery,
    useTheme,
    Grid,
    GridItem,
    Image
} from '@chakra-ui/react';
import ButtonModal from '../ButtonModal';
import '../../styles/styled.css';
import '../../styles/img-animation.css';
import './animation.css';

const Caracteristicas = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

    const icons = [
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/amenidades/icon1.svg' },
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/amenidades/icon2.svg' },
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/amenidades/icon3.svg' },
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/amenidades/icon4.svg'},
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/amenidades/icon5.svg' },
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/amenidades/icon6.svg' }
    ];

    const animacion = [
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/amenidades/img1.svg' },
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/amenidades/img2.png' },
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/Icon-flotante-mobile-1.svg' },
      { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/animacion2.svg' }
    ];

    useEffect(() => {
        const observerL = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show-caracteristicas');
          } else {
            entry.target.classList.remove('show-caracteristicas');
          }
        })
        })
          
        const hiddenElementsL = document.querySelectorAll('.hidden-left-caracteristicas');
        hiddenElementsL.forEach((el) => observerL.observe(el));
          
        const observerR = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('show-caracteristicas');
            } else {
              entry.target.classList.remove('show-caracteristicas');
            }
          })
        })
          
        const hiddenElementsR = document.querySelectorAll('.hidden-right-caracteristicas');
        hiddenElementsR.forEach((el) => observerR.observe(el));
    }, []);

    return ( 
        <div className='sections-caracteristicas'>
            <div className='hidden-left-caracteristicas'>
                <Flex
                    color={"#545454"}
                    position={"relative"}
                    width={"100%"}
                >
                    <Flex width={"100%"} flexDirection={"column"}>
                        <Grid
                            templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
                            px={isGreaterThanLg ? 0 : 8}
                            w={isGreaterThanLg ? "65%" : "100%"}
                            margin="auto"
                            pb={20}
                            gap="3em 1em"
                            fontWeight={600}
                            mr={"10%"}
                        >
                            <GridItem w="100%">
                                <Flex justifyContent="center" alignItems={"center"}>
                                    <img src={icons[0].imageUrl} 
                                        style={{ 
                                            height: isGreaterThanMd ? "80px" : "60px", 
                                            width: isGreaterThanMd ? "max-content" : "60px" }} alt="torre" />
                                    <Text fontSize={isGreaterThanMd ? "lg" : "md"} marginLeft={"0.5rem"}>
                                    2 recámaras
                                    </Text>
                                </Flex>
                            </GridItem>
                            <GridItem w="100%">
                                <Flex justifyContent="center" alignItems={"center"}>
                                    <img
                                        src={icons[1].imageUrl}
                                        style={{ 
                                            height: isGreaterThanMd ? "80px" : "60px", 
                                            width: isGreaterThanMd ? "max-content" : "60px" }} alt="depas" />
                                    <Text fontSize={isGreaterThanMd ? "lg" : "md"} marginLeft={"0.5rem"}>
                                        75 unidades
                                    </Text>
                                </Flex>
                            </GridItem>
                            <GridItem w="100%">
                                <Flex justifyContent="center" alignItems={"center"}>
                                    <img
                                        src={icons[2].imageUrl}
                                        style={{ 
                                            height: isGreaterThanMd ? "80px" : "60px", 
                                            width: isGreaterThanMd ? "max-content" : "60px" }} alt="cinta"/>
                                    <Text fontSize={isGreaterThanMd ? "lg" : "md"} marginLeft={"0.5rem"}>
                                    Desde 71 m<sup>2</sup>
                                    </Text>
                                </Flex>
                            </GridItem>
                            <GridItem w="100%">
                                <Flex justifyContent="center" alignItems={"center"}>
                                    <img
                                        src={icons[3].imageUrl}
                                        style={{ 
                                            height: isGreaterThanMd ? "80px" : "70px", 
                                            width: isGreaterThanMd ? "max-content" : "70px" }} alt="niveles" />
                                    <Text fontSize={isGreaterThanMd ? "lg" : "md"} marginLeft={"0.5rem"}>
                                    Seguridad 24/7
                                    </Text>
                                </Flex>
                            </GridItem>
                            <GridItem w="100%">
                                <Flex justifyContent="center" alignItems={"center"}>
                                    <img
                                        src={icons[4].imageUrl}
                                        style={{ 
                                            height: isGreaterThanMd ? "80px" : "50px", 
                                            width: isGreaterThanMd ? "max-content" : "50px" }} alt="seguridad" />
                                    <Text fontSize={isGreaterThanMd ? "lg" : "sm"} marginLeft={"0.5rem"}>
                                        Estacionamiento<br />subterráneo
                                    </Text>
                                </Flex>
                            </GridItem>
                            <GridItem w="100%">
                                <Flex justifyContent="center" alignItems={"center"}>
                                    <img
                                    src={icons[5].imageUrl}
                                    style={{ 
                                        height: isGreaterThanMd ? "80px" : "50px", 
                                        width: isGreaterThanMd ? "max-content" : "50px" }} alt="estacionamiento" />
                                    <Text fontSize={isGreaterThanMd ? "lg" : "sm"} marginLeft={"1rem"} >
                                    Pet friendly
                                    </Text>
                                </Flex>
                            </GridItem>
                        </Grid>
                        <Grid
                            templateColumns={isGreaterThanMd ? "repeat(2, 1fr)" : "repeat(1, 1fr)"}
                            px={isGreaterThanLg ? 0 : 8}
                            w={isGreaterThanLg ? "65%" : "100%"}
                            margin={isGreaterThanMd ? "auto" : "0px 0px 10% 0px"}
                            pb={isGreaterThanMd ? 10 : 0}
                            height={"30rem"}
                            gap="3em 1em"
                            fontWeight={600}
                            position={"relative"}
                            mr={isGreaterThanLg ? "5%" : "0px"}
                            p={isGreaterThanMd ? 10 : 0}
                        >
                            {isGreaterThanLg ? 
                                <GridItem w="400px">
                                    <Flex position={"absolute"} bottom={"10px"} left={"-34px"}>
                                        <Image src={animacion[0].imageUrl} zIndex={"-1"} width="320" height="320" alt='icon scroll'/>
                                    </Flex>
                                    <Flex justifyContent={"end"}>
                                        <Image className='img-flotante' src={animacion[1].imageUrl} width="420" height="420" alt='icon scroll'/>
                                    </Flex>
                                </GridItem> : null
                            }
                            <GridItem w="100%" margin={"auto"} paddingLeft={isGreaterThanMd ? 10 : 0}>
                                {isGreaterThanMd ? null : 
                                    <Image src={animacion[2].imageUrl} style={{ width: isGreaterThanMd ? "520px" : "100%" }} width="520" height="520" alt='icon scroll'/>
                                }
                                <Text
                                    fontSize={{ base: "30px", md: "42px", lg: "42px" }}
                                    fontWeight={300} mb={3}
                                    width={"max-content"}
                                    fontFamily={"ResistSansText"}
                                    textTransform={"uppercase"}
                                    margin={isGreaterThanMd ? 0 : "auto"}
                                    mt={isGreaterThanMd ? 0 : "3rem"}
                                >
                                    Tu depa desde el<br />5 % de enganche
                                </Text>
                                <Flex justifyContent={isGreaterThanMd ? "flex-start" : "center"} p={isGreaterThanMd ? 0 : 2}>
                                    <ButtonModal 
                                        title={"Quiero ser contactado"}
                                        bg={"#FFF"}
                                        color={"#C80A7F"}
                                        bgHover={"#C80A7F"}
                                        colorHover={"#FFF"}
                                        border={"2px solid #C80A7F"}
                                        thankYouPage={"thankyoupage-agenda-cita"}
                                        idForm={"0f89c480-10be-4d00-a260-5e047f7ad065"}
                                        />
                                </Flex>
                            </GridItem>
                        </Grid>
                    </Flex>
                </Flex>
            </div>
            <div className='hidden-right-caracteristicas'>
                <div className='wrapper-img-caracteristicas'>
                    <img id='animacion2' src={animacion[3].imageUrl} width="250" height="250" alt='icon scroll'/>
                </div>
            </div>
        </div>
    );
}
 
export default Caracteristicas;