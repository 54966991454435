import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import ButtonModal from './ButtonModal';

import "../styles/styled.css";
import '../styles/nav.css';

export default function Nav() {
  const { isOpen, onToggle } = useDisclosure();

  const icons = [
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/logo.svg' }
  ];

  return (
    <Box>
      <Flex
        bg={useColorModeValue('transparent', 'gray.800')}
        color={useColorModeValue('gray.600', 'gray.600')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={'center'}>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Image src={icons[0].imageUrl} boxSize={{base: '60px', md: '80px'}} alt="logo" />
          <Flex display={{ base: 'none', md: 'none', lg: 'flex' }} ml={20}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'center'}
          padding={"0px 1rem"}
          direction={'row'}>
          <ButtonModal 
            title={"Descargar Brochure"}
            bg={"#E69730"}
            color={"#FFF"}
            bgHover={"#BB7923"}
            colorHover={"#FFF"}
            thankYouPage={"thankyoupage-brochure"}
            idForm={"9d33bffc-d6c4-4587-8cd6-c4a98899dd93"}
            />
        </Stack>
        <Flex
          flex={{ base: 0.5, md: 'auto' }}
          justifyContent={"end"}
          display={{ base: 'flex', lg: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} color='#6F6F6F'/> : <HamburgerIcon w={5} h={5} color='#6F6F6F' />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('#5a5a5a', 'gray.100');
  const linkHoverColor = useColorModeValue('#545454', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4}  alignItems='center'>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label} alignItems='center'>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger id="popover" textAlign={"center"}>
              <Link
                href={navItem.href ?? '#'}
                fontSize={"16px"}
                fontWeight={500}
                fontFamily={"Montserrat, sans-serif"}
                color={linkColor}
                pr={10}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ lg: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: 'Proyecto',
    href: '#proyecto',
  },
  {
    label: 'Amenidades',
    href: '#amenidades',
  },
  {
    label: 'Prototipos',
    href: '#prototipos',
  },
  {
    label: 'Planes de pago',
    href: '#planes-pago'
  },
  {
    label: 'TyA',
    href: '#tya',
  },
  {
    label: 'Contacto',
    href: '#contacto',
  }
];