import React, { useState } from 'react';
import { Flex, IconButton, useMediaQuery, useTheme } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import "../styles/styled.css";

const Prototipos = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [activeIndex, setActiveIndex] = useState(0);

    const slidePrev = () => {
        activeIndex===0?(
          setActiveIndex(listImages[1].images.length-1)):
        setActiveIndex(activeIndex - 1);
    };
    
    const slideNext = () => {
        activeIndex===listImages[1].images.length-1?(
          setActiveIndex(0))
            : setActiveIndex(activeIndex + 1)
    };

    return (
      <Flex flexDirection={"column"} alignItems={"center"} id="prototipos">
        <Flex
            width={isGreaterThanMd ? "90%" : "95%"}
            direction="row"
            alignItems="center"
            placeContent={"center"}
        >
            <IconButton
                icon={<ChevronLeftIcon color={"#E69730"} boxSize={8} />}
                rounded="full"
                border="0"
                shadow="md"
                w={isGreaterThanMd ? 10 : 7}
                minW={isGreaterThanMd ? 10 : 7}
                h={isGreaterThanMd ? 10 : 7}
                transitionDuration=".3s"
                _hover={{ shadow: "lg" }}
                //disabled={page <= 1}
                onClick={slidePrev}
                position="relative"
                right={{ base: "-4", md: 10 }}
                bottom={{ base: "0", md: 16 }}
                bg="#FFF"
                zIndex="2"
            />
            <Flex flexDirection={"column"} width={"80%"} marginBottom={"2rem"}>
                <AliceCarousel
                    mouseTracking
                    //disableDotsControls
                    //animationType="fadeout"
                    animationDuration={800}
                    disableButtonsControls
                    activeIndex={activeIndex}
                    items={listImages[1].images}
                    responsive={true}
                    controlsStrategy="responsive"
                    animationType="fadeout"
                    autoPlay={true}
                    autoPlayInterval={5000}
                    infinite={true}
                    keyboardNavigation={true}
                    disableDotsControls
                    autoHeight={false}
                    maxHeight="200px"
                />
            </Flex>
            <IconButton
                icon={<ChevronRightIcon color={"#E69730"} boxSize={8} />}
                rounded="full"
                border="0"
                colorScheme="brand"
                shadow="md"
                w={isGreaterThanMd ? 10 : 7}
                minW={isGreaterThanMd ? 10 : 7}
                h={isGreaterThanMd ? 10 : 7}
                transitionDuration=".3s"
                _hover={{ shadow: "lg" }}
                bottom={{ base: "0", md: 16 }}
                onClick={slideNext}
                position="relative"
                left={{ base: "-4", md: 10 }}
                bg="#FFF"
                zIndex="2"
            />
        </Flex>
      </Flex>
    );
}
 
export default Prototipos;