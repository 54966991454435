import React from 'react';
import { 
    Flex, 
    Text,
    useMediaQuery, 
    useTheme,
    Image
} from '@chakra-ui/react';
import ButtonModal from './ButtonModal';
import '../styles/img-animation.css';
import '../styles/sitio.css';

const Sitio = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

    const animacion = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/sitio/icon2.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/sitio/img1.png' }
    ];

    return (
        <Flex
            color={"#545454"}
            width={"100%"}
            justifyContent={"flex-end"}
            flexDirection={isGreaterThanLg ? "row-reverse" : "column-reverse"}
        >
            <Flex width={isGreaterThanLg ? "50%" : "100%"} justifyContent={"center"} alignItems={"center"}>
                <Flex flexDirection={"column"} pt={isGreaterThanLg ? 0 : 20} 
                    textAlign={isGreaterThanLg ? "initial" : "center"} alignItems={isGreaterThanLg ? "initial" : "center"}>
                        <Text
                            mb={3}
                            fontSize={{ base: "3xl", md: "42px", lg: "42px" }}
                            fontWeight={300}
                            fontFamily={"ResistSansText"}
                            textTransform={"uppercase"}
                        >
                            + cerca de todo<br />lo que amas
                        </Text>
                        <ButtonModal 
                            title={"Quiero ser contactado"}
                            bg={"#FFF"}
                            color={"#C80A7F"}
                            bgHover={"#C80A7F"}
                            colorHover={"#FFF"}
                            border={"2px solid #C80A7F"}
                            thankYouPage={"thankyoupage-agenda-cita"}
                            idForm={"0f89c480-10be-4d00-a260-5e047f7ad065"}
                            />
                    </Flex>
            </Flex>
            <Flex position={"relative"} width={isGreaterThanLg ? "50%" : "100%"}>
                <Flex width={"100%"} className='img-nuevozapopan' justifyContent={isGreaterThanLg ? "end" : "center"}>
                    <Image className='img-flotante' src={animacion[1].imageUrl} width="420" height="420" alt='icon scroll'
                        style={{ width: isGreaterThanLg ? "420px" : "350px", height: isGreaterThanLg ? "420px" : "350px" }}/>
                    <Flex width={"100%"} zIndex={-1} className='icon-nuevozapopan' position={"absolute"} 
                        bottom={"-10"} right={isGreaterThanLg ? "40" : isGreaterThanMd ? "70px" : "80px"}
                        justifyContent={isGreaterThanLg ? "end" : "center"}>
                        <Image src={animacion[0].imageUrl} width="320" height="320" alt='icon scroll'
                            style={{ width: isGreaterThanLg ? "320px" : "250px", height: isGreaterThanLg ? "320px" : "250px" }}/>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
 
export default Sitio;