import React, { useEffect } from 'react';
import { 
    Flex, 
    Text,
    useMediaQuery, 
    useTheme,
    Image
} from '@chakra-ui/react';
import ButtonModal from '../ButtonModal';
import '../../styles/img-animation.css';
import './animation.css';

const NuevoZapopan = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

    const animacion = [
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/icons/animacion3.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/planespago/icon1.svg' },
        { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/centrozapopan/images/planespago/img1.png' }
    ];

    useEffect(() => {
        const observerL = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show-nuevozapopan');
                } else {
                    entry.target.classList.remove('show-nuevozapopan');
                }
            })
        })
          
        const hiddenElementsL = document.querySelectorAll('.hidden-left-nuevozapopan');
        hiddenElementsL.forEach((el) => observerL.observe(el));
          
        const observerR = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('show-nuevozapopan');
            } else {
              entry.target.classList.remove('show-nuevozapopan');
            }
          })
        })
          
        const hiddenElementsR = document.querySelectorAll('.hidden-right-nuevozapopan');
        hiddenElementsR.forEach((el) => observerR.observe(el));
    }, []);

    return ( 
        <div className='sections-nuevozapopan'>
            <div className='hidden-left-nuevozapopan'>
                <div className='wrapper-img-nuevozapopan'>
                <img id='animacion3' src={animacion[0].imageUrl} width="150" height="250" alt='icon scroll'/>
                </div>
            </div>
            <div className='hidden-right-nuevozapopan'>
                <Flex
                    color={"#545454"}
                    width={"100%"}
                    flexDirection={isGreaterThanLg ? "row" : "column-reverse"}
                >
                    <Flex width={isGreaterThanLg ? "50%" : "100%"} justifyContent={"center"} alignItems={"center"}>
                        <Flex flexDirection={"column"} pt={isGreaterThanLg ? 0 : 20} 
                            textAlign={isGreaterThanLg ? "initial" : "center"} alignItems={isGreaterThanLg ? "initial" : "center"}>
                            <Text
                                mb={3}
                                fontSize={{ base: "4xl", md: "42px", lg: "42px" }}
                                fontWeight={300}
                                fontFamily={"ResistSansText"}
                                textTransform={"uppercase"}
                            >
                                Vive el nuevo<br />centro de Zapopan
                            </Text>
                            <ButtonModal 
                                title={"Quiero ser contactado"}
                                bg={"#FFF"}
                                color={"#0882C6"}
                                bgHover={"#0882C6"}
                                colorHover={"#FFF"}
                                border={"2px solid #0882C6"}
                                thankYouPage={"thankyoupage-agenda-cita"}
                                idForm={"0f89c480-10be-4d00-a260-5e047f7ad065"}
                                />
                        </Flex>
                    </Flex>
                    <Flex position={"relative"} width={isGreaterThanLg ? "50%" : "100%"}>
                        <Flex width={"100%"} className='img-nuevozapopan' justifyContent={isGreaterThanLg ? "initial" : isGreaterThanMd ? "center" : "center"}>
                            <Image className='img-flotante' src={animacion[2].imageUrl} width="420" height="420" alt='icon scroll'
                                style={{ width: isGreaterThanLg ? "420px" : "350px", height: isGreaterThanLg ? "420px" : "350px" }}/>
                            <Flex width={isGreaterThanLg ? "auto" : isGreaterThanMd ? "100%" : "95%"} zIndex={-1} className='icon-nuevozapopan' position={"absolute"} 
                                bottom={"-10"} left={isGreaterThanLg ? "40" : isGreaterThanMd ? "70px" : "0px"}
                                justifyContent={isGreaterThanLg ? "initial" : isGreaterThanMd ? "center" : "end"}>
                                <Image src={animacion[1].imageUrl} width="320" height="320" alt='icon scroll'
                                    style={{ width: isGreaterThanLg ? "320px" : "250px", height: isGreaterThanLg ? "320px" : "250px" }}/>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </div>
        </div>
    );
}
 
export default NuevoZapopan;