import React from 'react';
import {
    Tab,
    Tabs,
    TabPanels,
    TabPanel,
    TabList,
    Divider,
    Flex,
    Text
} from '@chakra-ui/react';
import { list } from '../../resource';
import '../../styles/cotizador.css';

const TableMobile = () => {

    return ( 
        <Tabs variant='enclosed' flexDirection={"column"} alignItems={"center"}>
            <TabList width={"max-content"} flexDirection={"column"} margin={"auto"}>
                <Tab fontSize={"22px"} _selected={{ bg: "#E69730", color: "#fff", fontWeight: "500" }}>Contado</Tab>
                <Tab fontSize={"22px"} _selected={{ bg: "#E69730", color: "#fff", fontWeight: "500" }}>Contado comercial</Tab>
                <Tab fontSize={"22px"} _selected={{ bg: "#E69730", color: "#fff", fontWeight: "500" }}>Financiamiento</Tab>
                <Tab fontSize={"22px"} _selected={{ bg: "#E69730", color: "#fff", fontWeight: "500" }}>Hipotecario A</Tab>
                <Tab fontSize={"22px"} _selected={{ bg: "#E69730", color: "#fff", fontWeight: "500" }}>Hipotecario B</Tab>
            </TabList>
            <TabPanels mt={10}>
                {list.map((item, idx) => (
                    <TabPanel fontFamily={"Montserrat, sans-serif"} key={idx}>
                        <Flex flexDirection={"column"}>
                            <Flex flexDirection={"column"}>
                                <Text fontSize='lg' textTransform={"capitalize"} color={"#E69730"} fontWeight={700}>{`Descuento ${item.num}`}</Text>
                                <Text fontWeight={600} mb={5} mt={2}>{item.pay}</Text>
                                <Divider />
                            </Flex>
                        </Flex>
                        <Flex flexDirection={"column"}>
                            {item.tab.map((element, idx1) => (
                                <Flex key={idx1} flexDirection={"column"} lineHeight={2}>
                                    <Text fontSize='lg' textTransform={"inherit"} fontWeight={400}>{element.item1}</Text>
                                    <Text fontSize='lg' textTransform={"inherit"} fontWeight={400}>{element.item2}</Text>
                                    <Divider />
                                </Flex>
                            ))}
                        </Flex>
                    </TabPanel>            
                ))}
            </TabPanels>
        </Tabs>
    );
}
 
export default TableMobile;